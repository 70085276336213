// Generated by Framer (705c3f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {qHWla6YIW: {hover: true}};

const cycleOrder = ["qHWla6YIW"];

const serializationHash = "framer-DzPRd"

const variantClassNames = {qHWla6YIW: "framer-v-1386ucf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.25, ease: [0.12, 0.23, 0.5, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, linkName, newTab, width, ...props}) => { return {...props, GyAX2tSCy: newTab ?? props.GyAX2tSCy, Qm1WnjRgy: linkName ?? props.Qm1WnjRgy ?? "Link", sCkh7WaW9: link ?? props.sCkh7WaW9} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;linkName?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, sCkh7WaW9, Qm1WnjRgy, GyAX2tSCy, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qHWla6YIW", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={sCkh7WaW9} openInNewTab={GyAX2tSCy} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1386ucf", className, classNames)} framer-125blck`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qHWla6YIW"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"qHWla6YIW-hover": {opacity: 0.5}}} {...addPropertyOverrides({"qHWla6YIW-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.5px", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9)))"}}>Link</motion.p></React.Fragment>} className={"framer-1hsgumf"} data-framer-name={"Link"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"UF9XOj7OX"} style={{"--extracted-r6o4lv": "var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9))", "--framer-paragraph-spacing": "0px"}} text={Qm1WnjRgy} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DzPRd.framer-125blck, .framer-DzPRd .framer-125blck { display: block; }", ".framer-DzPRd.framer-1386ucf { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-DzPRd .framer-1hsgumf { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DzPRd.framer-1386ucf { gap: 0px; } .framer-DzPRd.framer-1386ucf > * { margin: 0px; margin-bottom: calc(4px / 2); margin-top: calc(4px / 2); } .framer-DzPRd.framer-1386ucf > :first-child { margin-top: 0px; } .framer-DzPRd.framer-1386ucf > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 25.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"LYc3xZfDz":{"layout":["auto","auto"]}}}
 * @framerVariables {"sCkh7WaW9":"link","Qm1WnjRgy":"linkName","GyAX2tSCy":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergD2TqHeFn: React.ComponentType<Props> = withCSS(Component, css, "framer-DzPRd") as typeof Component;
export default FramergD2TqHeFn;

FramergD2TqHeFn.displayName = "Link";

FramergD2TqHeFn.defaultProps = {height: 21, width: 25.5};

addPropertyControls(FramergD2TqHeFn, {sCkh7WaW9: {title: "Link", type: ControlType.Link}, Qm1WnjRgy: {defaultValue: "Link", displayTextArea: false, placeholder: "Link", title: "Link name", type: ControlType.String}, GyAX2tSCy: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramergD2TqHeFn, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})